<template>
  <section class="tabtwo_section">
    <div class="container-fluid">
      <div class="dashboard-top-section">
        <div class="dts-row-section">
          <div class="dts-box-outer" type="button" @click="temp_year = clv_year" data-toggle="modal"
            data-target="#clvyearmodel">
            <div class="dts-box-section">
              <div class="box_section">
                <div class="box_svg">
                  <ClvIcon />
                </div>
                <div class="box_text">
                  <h1>CLV year</h1>
                </div>
              </div>
              <div class="box-selection">
                <span>{{ clv_year }}</span>
              </div>
            </div>
          </div>
          <div class="dts-box-outer" type="button" @click="temp_currency = currency" data-toggle="modal"
            data-target="#currencySelectmodel">
            <div class="dts-box-section">
              <div class="box_section">
                <div class="box_svg">
                  <CurrencyIcon />
                </div>
                <div class="box_text">
                  <h1>Default Currency</h1>
                </div>
              </div>
              <div class="box-selection">
                <span>{{ currency }}</span>
              </div>
            </div>
          </div>
          <div class="dts-box-outer" type="button" @click="getShippingAndCogs" data-toggle="modal"
            data-target="#shippingandcogsmodel">
            <div class="dts-box-section">
              <div class="box_section">
                <div class="box_svg">
                  <ShippingIcon />
                </div>
                <div class="box_text">
                  <h1>shipping charges and COGS</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CLV year Model -->
      <div class="modal fade" id="clvyearmodel" tabindex="-1" role="dialog" aria-labelledby="clvyearmodelLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="clvyearmodelLabel">
                Select CLV Year
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="conter_section">
                <div class="counter">
                  <button class="counter--arrow-inc" :class="count >= 25 ? 'disable' : 'enable'" v-on:click="count++"
                    v-bind:disabled="count >= 25">
                    <span class="counter_svg counter_up">
                      <DecrementArrowIcon />
                    </span>
                  </button>
                  <input type="number" class="counter--output" v-model.number="count" min="1" max="25" />
                  <button class="counter--arrow-dec" :class="count <= 1 ? 'disable' : 'enable'" v-on:click="count--"
                    v-bind:disabled="count <= 1">
                    <span class="counter_svg">
                      <DecrementArrowIcon />
                    </span>
                  </button>
                </div>
                <div class="year_text">
                  <p>Year</p>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="save-btn" @click="setClvYear">
                Save
              </button>
              <button type="button" class="cancle-btn" data-dismiss="modal" aria-label="Close" id="close-clv-model">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- shipping and cogs model -->
      <div class="modal fade" id="shippingandcogsmodel" tabindex="-1" role="dialog"
        aria-labelledby="shippingandcogsmodelLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="shippingandcogsmodelLabel">
                Shipping Charges and COGS
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-5">
              <div class="shipping-cogs">
                <div class="form-group">
                  <div class="main-section">
                    <label for="shipping">1. Average Shipping Cost(online stores only)</label>
                  </div>
                  <div class="input-section w-50">
                    <div class="main-input d-flex">
                      <input type="number" name="shipping" class="form-control" step="0.01" v-model="shipping_charges"
                        @keyup="validateShipping" @blur="validateShipping" />
                      <span>{{ default_currency }}</span>
                    </div>
                    <span class="error">{{ this.shipping_error }}</span>
                  </div>

                </div>
                <div class="form-group">
                  <div class="main-section">
                    <label for="cogs">2. COGS, % is to exclude direct shipping, marketing cost and article price.
                    </label>
                  </div>
                  <div class="input-section w-50">
                    <div class="main-input d-flex">
                      <input type="number" name="cogs" class="form-control " min="0" v-model="cogs"
                        @keyup="validateCogs" @blur="validateCogs" />
                      <span>% </span>

                    </div>
                    <span class="error">{{ this.cogs_error }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="save-btn" @click="updateShippingCogs">
                Save
              </button>
              <button type="button" class="cancle-btn" data-dismiss="modal" aria-label="Close"
                id='close-shipping-model'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Set currency Model -->
      <div class="modal fade" id="currencySelectmodel" tabindex="-1" role="dialog"
        aria-labelledby="currencySelectmodelLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="currencySelectmodelLabel">
                Select Currency
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <select
                  class="form-control my-5"
                  style="appearance: auto"
                  v-model="temp_currency"
                >
                  <option v-for="currency in currency_options" :key="currency">
                    {{ currency }}
                  </option>
                </select> -->
              <Multiselect
                :id="'currency'"
                v-model="temp_currency"
                :options="currency_options"
                :canClear="false"
                class="my-5"
              />
            </div>
            <div class="modal-footer">
              <button type="submit" class="save-btn" @click="setCurrency">
                Save
              </button>
              <button type="button" class="cancle-btn" data-dismiss="modal" aria-label="Close"
                id="close-currency-model">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>
<script>
import ClvIcon from "../../icons/clvicon.vue";
import CurrencyIcon from "../../icons/currencyicon.vue";
// import ApiIcon from "../../icons/api.vue";
// import SetIcon from "../../icons/set.vue";
// import CampaignKPIIcon from  "../../icons/campaignKPI.vue"
import ShippingIcon from "../../icons/shipping.vue";
import currencyConverter from "../../Core/helpers/currencyConverter";
import DecrementArrowIcon from "../../icons/decrementArrow.vue";
import "vue-multiselect/dist/vue-multiselect.css";
import Multiselect from 'vue-multiselect'
import { API_URL } from "@/Core/helpers/utils";
import {
  UPDATE_CLV_YEAR,
  CHANGE_ORG_CURRENCY,
  GENERATE_RECEIPT_API_TOKEN,
  GENERATE_RECOMMENDATIONS_API_TOKEN,
  UPDATE_ORG_EXTERNAL_API,
  GET_EXTERNAL_API,
  GET_SHIPPING_COGS,
  UPDATE_SHIPPING_COGS,
  UPDATE_CURRENCY,
  CHANGE_ORG_CLV_YEAR
} from "@/Core/store/action-types";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Settings",
  components: {
    ClvIcon,
    CurrencyIcon,
    // ApiIcon,
    DecrementArrowIcon,
    Multiselect,
    ShippingIcon,
  },

  data() {
    return {
      clv_year: 10,
      currency: "USD",
      currency_options: ["USD", "EUR", "SEK", "GBP"],
      temp_currency: "USD",
      temp_year: 1,
      selectedApi: "",
      selectedKey: "",
      copybuttonText: "copy",
      keyCopyButtonText: "copy",
      keyURL: "",
      storeStatusList: [
        { id: 1, name: "Online" },
        { id: 0, name: "Offline" },
      ],
      selectedStore: null,
      selectedStoreId: null,
      shipping_charges: 0,
      cogs: 0,
      shipping_error: "",
      cogs_error: "",
    };
  },
  computed: {
    ...mapGetters("org", ["default_clv_year", "default_currency"]),
    count: {
      get() {
        console.log("get");
        return this.temp_year;
      },
      set(newValue) {
        console.log("seet", newValue);
        this.temp_year = newValue;
      },
      // this.$emit('input', newValue) }
    },
  },
  methods: {
    ...mapActions("user", [UPDATE_CURRENCY, UPDATE_CLV_YEAR]),
    ...mapActions("receipt", [GENERATE_RECEIPT_API_TOKEN, GENERATE_RECOMMENDATIONS_API_TOKEN]),
    ...mapActions("org", [
      UPDATE_ORG_EXTERNAL_API,
      GET_EXTERNAL_API,
      GET_SHIPPING_COGS,
      UPDATE_SHIPPING_COGS,
      CHANGE_ORG_CLV_YEAR,
      CHANGE_ORG_CURRENCY
    ]),
    async Example() {
      const id = await currencyConverter("USD", "USD", 4);
      console.log("output", id);
    },
    setClvYear() {
      this.clv_year = this.temp_year;
      let params = {
        clv_year: this.clv_year
      }
      this[CHANGE_ORG_CLV_YEAR](params).then(() => {
        this[UPDATE_CLV_YEAR](params)
        this.$alertify.notify(`CLV year updated successfully.`, "success", 3);
      })
        .catch((error) => {
          console.log(error);
          this.$alertify.notify(
            `Some problem occued, please try again. `,
            "error",
            3
          );
        });
      document.getElementById("close-clv-model").click();
    },
    setCurrency() {
      console.log("setCurrency", this.temp_currency);
      if (!this.temp_currency) {
        this.$alertify.notify(`Please select currency.`, "error", 3);
        return;
      }
      this.currency = this.temp_currency;
      let params = {
        currency: this.currency
      }
      this[CHANGE_ORG_CURRENCY](params).then(() => {
        this[UPDATE_CURRENCY](params)
        this.$alertify.notify(`Currency updated successfully.`, "success", 3);
      })
        .catch((error) => {
          console.log(error);
          this.$alertify.notify(
            `Some problem occued, please try again. `,
            "error",
            3
          );
        });
      document.getElementById("close-currency-model").click();
    },
    coptText() {
      navigator.clipboard.writeText(this.selectedApi);
      this.copybuttonText = "copied";
    },
    copyToken() {
      navigator.clipboard.writeText(this.keyURL);
      this.keyCopyButtonText = "copied";
    },
    generateApi() {
      this.copybuttonText = "copy";
      this[GENERATE_RECEIPT_API_TOKEN](this.selectedStoreId).then((resp) => {
        let generated_api = `${process.env.VUE_APP_RECEIPT_APT_GATEWAY_URL}/api/v1/add_receipt/merchant/${resp.company_id}/sales_channel/${this.selectedStoreId}/token/${resp.receipt_auth_token}`;
        this.selectedApi = generated_api;
        let params = {
          sales_channel_online_status: this.selectedStoreId,
          external_api: generated_api,
        };
        this[UPDATE_ORG_EXTERNAL_API](params);
        this.$alertify.notify(`API generated successfully .`, "success", 3);
      });
    },
    getStoreList() {
      this.copybuttonText = "copy";
      // this[GET_STORE_BY_MERCHANT_ID]().then(resp => this.storeList = resp)
    },
    generateKey() {
      this.keyCopyButtonText = "copy";
      this.keyCopyButtonText = "copy";
      this[GENERATE_RECOMMENDATIONS_API_TOKEN](this.selectedStoreId).then((resp) => {
        let generated_api = `${API_URL}/api/v1/recommendations_by_external_id/${resp.token}/<Enter external id of customer>/`;
        this.keyURL = generated_api;
        this.$alertify.notify(`Token generated successfully .`, "success", 3);
      });
    },
    getShippingAndCogs() {
      let params = {
        currency: this.default_currency,
      };
      this[GET_SHIPPING_COGS](params).then((response) => {
        this.shipping_charges = response.shipping_charges.toFixed(2);
        this.cogs = response.cogs ? response.cogs : 0;
      });
    },
    validateShipping() {
      console.log(this.shipping_charges);
      if (
        this.shipping_charges == null ||
        this.shipping_charges == "" ||
        this.shipping_charges < 0
      )
        this.shipping_error = "Enter valid shipping charge";
      else this.shipping_error = "";
    },
    validateCogs() {
      if (
        this.cogs == null ||
        this.cogs == "" ||
        this.cogs < 0 ||
        this.cogs > 100
      )
        this.cogs_error = "Enter valid COGS";
      else this.cogs_error = "";
    },
    updateShippingCogs() {
      this.validateShipping();
      this.validateCogs();
      if (this.shipping_error == "" && this.shipping_error == "") {
        let params = {
          avg_shipping_charges: this.shipping_charges,
          cogs_value: this.cogs,
          shipping_charges_currency: this.default_currency,
        };
        this[UPDATE_SHIPPING_COGS](params)
          .then(() => {
            this.$alertify.notify(
              `COGS and shipping charge updated successfully. `,
              "success",
              3
            );
          })
          .catch((error) => {
            console.log(error);
            this.$alertify.notify(
              `Some problem occued, please try again. `,
              "error",
              3
            );
          });
      }
      document.getElementById("close-shipping-model").click();
    },
  },
  mounted() {
    this.clv_year = this.default_clv_year;
    this.currency = this.default_currency;
  },
  watch: {
    selectedStoreId() {
      if (this.selectedStoreId) {
        this[GET_EXTERNAL_API](this.selectedStoreId)
          .then((res) => {
            this.selectedApi = res.external_api;
          })
          .catch(() => {
            this.selectedApi = "";
          });
      } else {
        this.selectedApi = "";
      }
    },
  },
};
</script>

<style></style>

<style scoped>
.app>*+* {
  margin-top: 32px;
}




.box_section[data-v-436a8b24] {
  background: #fff;
  /* width: 350px; */
  height: 247px;
  margin: 0 auto;
  padding: 5px;
}

.box_text h1 {
  margin: 0;
  padding: 0;
  margin-top: 32px;
  font-size: 20px;
  font-weight: 700;
  color: #3f3f40;
}

.box_text span {
  font-size: 19px;
  font-weight: 400;
}

.dashboard-top-section .dts-row .dts-box-outer .dts-box {
  background: #ffffff;
  padding: 46px 30px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.dashboard-top-section {
  position: relative;
}

.year_section {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.year_section p {
  margin: 0;
  font-size: 30px;
}

.modal_body_content {
  width: 300px;
  background: white;
  margin: 0 auto;
  height: 0px;
  height: 120px;
}

.counter {
  display: flex;
  flex-direction: column;
  width: 90px;
}

.currency-convert {
  width: 140px !important;
}

button.counter--arrow-inc {
  border: 0;
  background: none;
}

button.counter--arrow-dec {
  background: transparent;
  border: 0;
}

span.counter_svg svg {
  width: 40px;
  height: 40px;
  fill: lightgray;
}

.enable span.counter_svg svg:hover {
  fill: gray;
}

button.counter--arrow-inc {
  transform: rotate(180deg);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.counter--output {
  text-align: center;
  font-size: 50px;
  padding: 15px;
  border: 0px;
  color: #5e66fa;
  background-color: #fff;
}

.conter_section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.year_text p {
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  color: #3f3f40 !important;
}

.box-selection {
  display: flex;
  align-items: center;
}

.success-btn:hover {
  background: #fff;
  color: #0bc984;
}

.success-btn {
  border: 1px solid #0bc984;
  background: #0bc984;
  font-size: 14px;
  padding: 9px 16px;
  color: #fff;
  border-radius: 5px;
}

.multiselect-search {
  left: 12px !important;
}

.shipping-cogs .form-group {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  line-height: initial;
}

.shipping-cogs .main-input {
  display: flex !important;
  align-items: center;
  gap: 9px;
}

.shipping-cogs .main-section {
  width: 70%;
}

.shipping-cogs .form-group input {
  width: 100px;
  margin-left: 30px;
}

/* .shipping-cogs .input-section { */
/* display: flex; */
/* justify-content: center; */
/* align-items: center;
  gap: 10px;
} */
</style>
