<template>
	<div>
		<Loading :active="isLoading" :is-full-page="fullPage"></Loading>
		<MainMenu />
		<TopBar
		title="Inventory"
		:author="{
			home: 'Home',
			cart: 'Inventory'
		}"
		/>
		<InventoryTable />
	</div>
</template>

<script>
import InventoryTable from "./InventoryTable.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";

export default {
	name: "Inventory",
	components: {
		Loading,
		InventoryTable,
		MainMenu,
		TopBar,
	},
	data() {
		return {
			fullPage: true,
			isLoading: true,
		};
	},
	mounted() {
		setTimeout(() => {
			this.isLoading = false;
		}, 2000);
	},
};
</script>