<template>
  <div class="contant-box-main customer-list-box">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img src="@/../public/assets/images/star-icon.png" alt="" /> Articles Re-stocking (National warehouse)
        </h6>
        <div class="">
          <!-- <label for="adminfilter">Organization</label> -->
          <select
            class="for"
            id="adminfilter"
            v-model="time"
            @change="onTimeChange"
          >
            <option v-for="option in timeOptions" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
        <!-- <span class="gray-text">from {{ one_month_ago_date() }}</span> -->
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow?'h-100':''">
          <div class="loader-main" v-if="loadindShow">
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
           </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Purchase Count</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="article in top_selling_articles" :key="article.id">
                <td class="font-light-text" width="20%">
                  {{ article.article__number }}
                </td>
                <td width="60%">{{ article.article__name }}</td>
                <td class="font-light-text" width="20%">
                  {{ article.quantity }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="contant-footer d-flex justify-content-end">
      <a
        role="button"
        data-toggle="modal"
        data-target="#restockArticleNationalWarehouseListModel"
        class="view-more-btn"
        @click="initSellingArticles(true)"
      >
        View All
        <img src="@/../public/assets/images/right-sky.png" alt=""
      /></a>
    </div>
  </div>
  <div
    class="modal fade"
    id="restockArticleNationalWarehouseListModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="valubaleCustomerListModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="restockArticleNationalWarehouseListModel">
            Articles re-stocking (National warehouse)
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-12">
                  <div class="contant-header">
                    <h6>
                      <img
                        src="@/../public/assets/images/star-icon.png"
                        alt=""
                      />
                      Articles re-stocking (National warehouse)
                    </h6>
                    <a
                      href="javascript:void(0);"
                      class="view-more-btn"
                      @click="createAudienceMode = !createAudienceMode"
                      >+ create audience</a
                    >
                    <button
                      type="button "
                      class="save-btn"
                      @click.prevent="initCustomerList(true)"
                    >
                      Export List
                    </button>
                  </div>
                  <div class="form-group" :class="{ 'has-error': pref_type_error }">
                        <label for="apref_type">Article Preference Type</label>
                          <Multiselect
                            v-model="pref_type"
                            :options="pref_options"
                            label="key"
                            valueProp="value"
                            placeholder="Select article preference type"
                          />
                           <span class="error">{{ pref_type_error }}</span>
                  </div>
                  <div v-if="createAudienceMode">
                    <!-- <div class="d-flex d-flex justify-content-between"> -->
                    <div
                      class="form-group"
                      :class="{ 'has-error': errMsg }"
                    >
                      <label class="form-label w-80">Audience Name</label>
                      <div class="d-flex" :style="'gap:16px'">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Name"
                          v-model="audienceName"
                          @keyup="validateName"
                          @blur="validateName"
                        />
                        <button
                          type="button"
                          class="save-btn"
                          @click.prevent="initCustomerList"
                        >
                          Create
                        </button>
                      </div>
                      <span class="error">{{ errMsg }}</span>
                    </div>
                  </div>
                  <!-- <div class="mt-4"> -->
                  <!-- <button type="button " class="save-btn">Create</button> -->
                  <!-- </div> -->
                  <!-- </div> -->
                </div>
              </div>
            </form>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-lg-12">
                  <div class="model_table">
                    <div class="table-wrp overflow-auto position-relative">
                      <div class="loader-main" v-if="loadindShow">
                          <div
                            class="spinner-border"
                            role="status"
                            style="width: 36px; height: 36px; color: #5e66fa"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      <table class="table my-table-wrp table-sm" id="my-table">
                        <thead>
                          <tr>
                            <th width="5%" class="pl-0">
                              <div class="checkbox-list">
                                <label class="checkbox">
                                  <input
                                    @change="AddRemoveAllArticles()"
                                    class="checkbox-input"
                                    type="checkbox"
                                    :checked="isAllSelected"
                                  />
                                  <span class="checkbox-checkmark-box">
                                    <span class="checkbox-checkmark"></span>
                                  </span>
                                </label>
                              </div>
                            </th>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Purchase count</th>
                            <!-- <th>Purchase Count</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="article in all_selling_articles? all_selling_articles.slice(
                                  10 * (page_model - 1),
                                  page_model * 10
                                )
                              : []"
                            :key="article.article__id"
                            :class="
                              checkedFilter(article.article__id)
                                ? 'active-row selected'
                                : ''
                            "
                          >
                            <td width="5%">
                              <div class="checkbox-list">
                                <label class="checkbox">
                                  <input
                                    @change="
                                      AddRemoveArticles(article.article__id)
                                    "
                                    class="checkbox-input"
                                    type="checkbox"
                                    :checked="
                                      checkedFilter(article.article__id)
                                    "
                                  />
                                  <span class="checkbox-checkmark-box">
                                    <span class="checkbox-checkmark"></span>
                                  </span>
                                </label>
                              </div>
                            </td>
                            <td class="font-light-text" width="20%">
                              {{ article.article__number }}
                            </td>
                            <td width="55%">{{ article.article__name }}</td>
                            <td class="font-light-text" width="20%">
                              {{ article.quantity }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      :page="page_model"
                      :totalPages="
                        all_selling_articles
                          ? Array(Math.ceil(all_selling_articles.length / 10))
                              .fill(0)
                              .map((e, i) => i + 1)
                          : []
                      "
                      :name="'Articles'"
                      :count="
                        all_selling_articles ? all_selling_articles.length : 0
                      "
                      :incrementpage="incrementpage_model"
                      :decrementpage="decrementpage_model"
                      :setpage="setpage_model"
                      :perpage="10"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-btns">
            <button
              type="button"
              class="cancle-btn"
              @click="oncancel"
              data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  TOP_SELLING_ARTICLES,
  CREATE_AUDIENCE,
  LIST_CUSTOMERS,
} from "@/Core/store/action-types";
import Pagination from "../../Core/Pagination.vue";
import Multiselect from "@vueform/multiselect";
export default {
  name: "TopSellingArticles",
  components: {
    Pagination,
    Multiselect
  },
  data() {
    return {
      top_selling_articles: [],
      all_selling_articles: [],
      customerIDs: [],
      choosen_articles: [],
      createAudienceMode: false,
      page_model: 1,
      nameError: false,
      errMsg: "",
      audienceName: "",
      time: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
      isAllSelected: false,
      pref_options: [
        { value: "has_bought", key: "Has bought" },
        {
          value: "likely_to_buy",
          key: "Likely to buy ",
        },
        {
          value: "similar_articles",
          key: "Similar articles",
        },
        {
          value: "likely_to_buy_again",
          key: "Likely to buy Again",
        },
        { value: "all_fields", key: "All Fields" },
      ],
      pref_type: "",
      pref_type_error: "",
      loadindShow:false,
    };
  },
  methods: {
    ...mapActions("article", [TOP_SELLING_ARTICLES]),
    ...mapActions("audience", [CREATE_AUDIENCE]),
    ...mapActions("customer", [LIST_CUSTOMERS]),
    initSellingArticles(isAll = false) {
      let params = {
        duration:
          this.time == "month"
            ? 30
            : this.time == "week"
            ? 7
            : this.time == "quater"
            ? 90
            : this.time == "half a year"
            ? 180
            : 365,
        all: isAll ? isAll : null,
      };
      this.loadindShow = true
      this[TOP_SELLING_ARTICLES](params)
        .then((result) => {
          if (isAll) this.all_selling_articles = result;
          else this.top_selling_articles = result;
        })
        .catch(() => (this.top_selling_articles = []))
        .finally(()=>this.loadindShow=false)
    },
    onAudienceCreate() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else if (this.customerIDs.length <= 0) {
        this.$alertify.notify(
          "No customers found to create audience",
          "error",
          3
        );
      } else {
        this.nameError = false;
        let payload = {
          members: this.customerIDs,
          name: this.audienceName,
          articles: this.choosen_articles,
          article_pref_type:this.pref_type,
          gender: "All",
          churn_status: 'All',
          age_min: 0,
          age_max: 100,
        };

        this[CREATE_AUDIENCE](payload)
          .then(() => {
            window.$(`#${this.createModelID}`).modal("hide");
            this.$alertify.notify(
              `${this.audienceName} successfully created`,
              "success",
              3
            );
            this.createAudienceMode = false;
          })
          .catch((e) => {
            this.errMsg = e.message;
            this.$alertify.notify(e.message, "error", 3);
          });
      }
    },
    AddRemoveAllArticles() {
      if (this.isAllSelected) {
        this.choosen_articles = [];
        this.isAllSelected = false;
      } else {
        this.choosen_articles = this.getAllArticleIDs();
        this.isAllSelected = true;
      }
    },
    getAllArticleIDs() {
      return this.all_selling_articles.map((a) => a.article__id);
    },
    getCustomerIDs(customers) {
      return customers.map((a) => a.id);
    },
    initCustomerList(export_list = false) {
      if (this.choosen_articles.length <= 0)
        this.$alertify.notify("Please select articles", "error", 3);
      else if(!this.pref_type || this.pref_type =="")
       this.pref_type_error = "please select articles preference type";
      else {
        let options = {
          params: {
            pref_type: this.choosen_articles.length != 0 ? this.pref_type : null,
            prefs: this.choosen_articles != 0 ? this.choosen_articles : null,
            bought_all: false,
          },
        };
        this[LIST_CUSTOMERS](options)
          .then((resp) => {
            console.log("export_list", export_list);
            if(resp && resp?.length==0)this.$alertify.notify(
                "No customers found for choosen articles and prefrence type",
                "error",
                3
              );
            else if (export_list == true) this.generateCSV(resp);
            else {
              this.customerIDs = this.getCustomerIDs(resp);
              this.onAudienceCreate();
            }
          })
          .catch((err) => {
            console.log("error", err);
          });
      }
    },
    generateCSV(customers) {
      let arrData = [
        {
          customers: customers,
        },
      ];
      window.$(`#exportCsvModel`).modal("hide");
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0].customers[0] || {}).join(","),
        ...arrData[0].customers.map((item) => Object.values(item).join(",")),
      ].join("\n");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `selling-article-customers-${this.time}.csv`
      );
      link.click();
    },
    AddRemoveArticles(id) {
      this.isAllSelected = false;
      setTimeout(() => {
        if (this.choosen_articles.includes(id)) {
          this.choosen_articles = this.choosen_articles.filter(
            (article_id) => article_id != id
          );
        } else {
          this.choosen_articles.push(id);
        }
      }, 300);
    },
    checkedFilter(id) {
      if (this.choosen_articles.includes(id)) return true;
      else return false;
    },
    onTimeChange() {
      this.initSellingArticles();
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
  },
  watch:{
    pref_type(value){
      console.log("ff",value)
      if(this.choosen_articles.length>0 && (value =="" || !value))this.pref_type_error = "please select articles preference type";
      else this.pref_type_error=''
    },
    choosen_articles(value){
      if(value.length == 0) {
        this.pref_type_error='',
        this.errMsg = "";
        }
    },
    audienceName(value){
      if (!value) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } 
      else if (value.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      }
      else {
        this.nameError = false;
        this.errMsg = "";
      }
    }
  },
  mounted() {
    this.initSellingArticles();
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
}
</style>