<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="42px" height="42px">
        <g fill="#1E3A8A">
            <!-- Cart Body -->
            <path
                d="M16 54a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm0-2a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM48 54a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm0-2a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM4 4h4l8 16h36l-4 16H18l-2 4h36l4-16H16l-8-16H0V4h4z" />
            <!-- Boxes -->
            <rect x="12" y="12" width="28" height="12" rx="2" fill="#1E3A8A" />
            <rect x="12" y="28" width="28" height="12" rx="2" fill="#1E3A8A" />
        </g>
    </svg>
</template>

<script>
export default {
    name: "InventoryIcon",
};
</script>

<style scoped></style>