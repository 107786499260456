import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/receipts/`;

export default {
  get_detail(token, params, objId) {
    return axios.get(`${RESOURCE_URL}${objId}/`, {
      params,
      headers: authHeader(token),
    });
  },
  get_receipts(token) {
    return axios.get(`${RESOURCE_URL}`, {
      headers: authHeader(token),
    });
  },
  generate_add_receipt_token(token, store_online_status) {
    return axios.get(
      `${API_URL}/api/v1/generate_add_receipt_api/${store_online_status}`,
      {
        headers: authHeader(token),
      }
    );
  },
  generate_recommendations_token(token) {
    return axios.get(
      `${API_URL}/api/v1/generate_token/`,
      {
        headers: authHeader(token),
      }
    );
  },
  get_receipt_stats(token,org_id) {
    return axios.get(
      `${API_URL}/api/v1/get_org_receipt_analytics/${org_id}`,
      {
        headers: authHeader(token),
      }
    );
  },
  article_file_upload(token, file) {
    let params = new FormData();
    params.append("file", file);
    return axios.post(`${API_URL}/api/v1/upload_article_file/`, params, {
      headers: authHeader(token),
    });
  },
  inventory_file_upload(token, file) {
    let params = new FormData();
    params.append("file", file);
    return axios.post(`${API_URL}/api/v1/upload_inventory_file/`, params, {
      headers: authHeader(token),
    });
  },
  receipt_file_upload(token, file) {
    let params = new FormData();
    params.append("receipts", file);
    return axios.post(`${API_URL}/api/v1/add_receipt_file/`, params, {
      headers: authHeader(token),
    });
  },
  shopify_receipt_file_upload(token, files) {
    let params = new FormData();
    for (let i = 0; i < files.length; i++) {
      params.append("receipts", files[i]);
    }
    return axios.post(`${API_URL}/api/v1/add_shopify_receipt_file/`, params, {
      headers: authHeader(token),
    });
  },
  multiple_receipt_file_upload(token, files) {
    let params = new FormData();
    for (let i = 0; i < files.length; i++) {
      params.append("receipts", files[i]);
    }
    return axios.post(`${API_URL}/api/v1/add_multiple_receipt_file/`, params, {
      headers: authHeader(token),
    });
  },
};
