<template>
	<div class="dashboard-top-section">
		<div class="contant-box-main">
			<div class="data-heading-wrp heading-content">
				<div class="data-heading-btn">
					<button @click="showCreateDialog">New Warehouse</button>
				</div>
				<!-- update shipping button start -->
				<div class="data-heading-wrp-button">
					<div class="update_btn">
						<button @click="showupdateshippingDialog">Update shipping days</button>
					</div>
					<div class="data-heading-btn" v-if="createpermission">
						<button @click="showupdateshippingDialog">
							+ create new shipping charge
						</button>
					</div>
				</div>
				<!-- update shipping button end -->
			</div>
			<div class="listWarehouse">
				<h1 class="listWarehouse_title">List view of warehouses</h1>
				<div class="accordion" v-for="warehouse in warehouse_data" :key="warehouse.id">
					<div class="handle">
						<div class="content-model">
							<span @click="showUploadDialog">{{ warehouse.name }} ({{ warehouse.type }})</span>
							<div class="button-group" style="float: right;">
								<!-- Conditional rendering for central/production warehouses -->
								<template v-if="warehouse.type === 'central/production'">
									<button class="btn btn-primary" @click="showUpdateStockDialog(warehouse.id)">Update
										stock levels</button>
									<button class="btn btn-success"
										@click="showMoveStockDialog(warehouse.id)">Shipping</button>
								</template>
							</div>
						</div>
						<div class="arrow-container" @click="toggleAccordion">
							<span class="arrow"></span>
						</div>
					</div>
					<div class="content">
						<table id="table">
							<tr>
								<th>Article ID</th>
								<th>Article name</th>
								<th>Inventory (no of)</th>
								<th>Inventory expected to last</th>
								<th>Volume in transit</th>
								<!-- <th>Inventory status</th> -->
								<th></th>
							</tr>
							<tr v-for="item in (warehouse.type === 'central/production' ? warehouse.article_data : warehouse.stock_data)"
								:key="item.id">
								<td>{{ warehouse.type === 'central/production' ? item.number : item.number }}</td>
								<td>{{ warehouse.type === 'central/production' ? item.name : item.article_name }}</td>
								<td>{{ warehouse.type === 'central/production' ? item.central_stock : item.quantity }}</td>
								<td>{{ warehouse.average_shipping_days + " days" }}</td>
								<td>{{ warehouse.type === 'central/production' ? item.volume_in_transit : item.volume_in_transit }}</td>
								<!-- <td class="inventory-status">
									<span
										:class="getStatusClass(warehouse.type === 'central/production' ? item.inventory_status : 'N/A')"></span>
									<div class="tooltip">{{ warehouse.type === 'central/production' ? item.status :
										'N/A' }}</div>
								</td> -->
								<td
									v-if="warehouse.type === 'national/distribution' && warehouse.stock_data.some(stock => stock.article_status === 'on hold' && stock.article === item.article)">
									<button class="btn btn-primary"
										@click="confirmShipping(item.article, warehouse.id, item.volume_in_transit)">Confirm
										Shipping</button>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Create Warehouse modal Window -->
	<div class="modal fade" id="createWarehousModel" tabindex="-1" role="dialog" aria-labelledby="createDialogLabel"
		aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="createDialogLabel">
						Create Warehouse
					</h5>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<label for="name">Warehouse name<span class="required-field text-danger">*</span></label>
						<input type="text" class="form-control" placeholder="Warehouse name" v-model="formData.name"
							name="name">
					</div>
					<div class="form-group">
						<label for="warehouse_type">Warehouse Type (central/production) OR (national/distribution)<span
								class="required-field text-danger">*</span></label>
						<select class="form-select" v-model="formData.warehouse_type" name="warehouse_type">
							<option v-for="type in warehouse_type_initiate" :key="type">{{ type }}</option>
						</select>
					</div>
					<div class="form-group">
						<div v-if="formData.warehouse_type !== 'central/production' && formData.warehouse_type !== ''">
							<label for="parent">Select Central/Production Warehouse</label>
							<select class="form-select" v-model="formData.parent" name="parent">
								<option value="Connet to central warehouse as a supplier to that warehouse" disabled
									selected>Connet to central warehouse as a supplier to that warehouse</option>
								<option v-for="warehouse in warehouse_data.filter(w => w.type === 'central/production')"
									:key="warehouse.id" :value="warehouse.id">
									{{ warehouse.name }}
								</option>
							</select>
							<!-- Tooltip for hover -->
							<div class="tooltip">Connect your National warehouse to a central warehouse in order to
								calculate when inventory levels need to be re-stocked.</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="save-btn" @click="saveWarehouse">Save</button>
					<button type="button" class="cancle-btn" data-bs-dismiss="modal"> Cancel </button>
				</div>
			</div>
		</div>
	</div>

	<!-- Move Stock Modal -->
	<div class="modal fade" id="moveStockModal" tabindex="-1" role="dialog" aria-labelledby="moveStockLabel"
		aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="moveStockLabel">Move Stock</h5>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<label for="warehouse-select">Select Warehouse (national/distribution)</label>
						<select v-model="selectedWarehouse" @change="updateArticles" class="form-select">
							<option v-for="warehouse in nationalWarehouses" :key="warehouse.id" :value="warehouse.id">
								{{ warehouse.name }}
							</option>
						</select>
					</div>
					<div class="form-group">
						<label for="article-select">Select Article</label>
						<select v-model="selectedArticle" @change="updateMaxStock" class="form-control">
							<option v-for="article in articles" :key="article.id" :value="article.id">
								{{ article.name }}
							</option>
						</select>
					</div>
					<div class="form-group">
						<label for="move-stock">Move Stock</label>
						<input type="number" class="form-control" id="move-stock" v-model="moveStock" :max="maxStock"
							placeholder="Enter quantity" />
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="save-btn" @click="saveMoveStock">Move</button>
					<button type="button" class="cancle-btn" data-bs-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>

	<!-- Update Stock Levels Modal -->
	<div class="modal fade" id="updateStockModal" tabindex="-1" role="dialog" aria-labelledby="updateStockLabel"
		aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="updateStockLabel">{{ selectedWarehouseName }}</h5>
					<!-- Display warehouse name -->
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<label for="article-select">Select Article</label>
						<select class="form-select" v-model="selectedArticle" id="article-select"
							@change="updateCurrentStock">
							<option v-for="article in articles" :key="article.id" :value="article.id">
								{{ article.name }}
							</option>
						</select>
					</div>
					<div class="form-group">
						<label for="current-stock">Current Stock Level</label>
						<input type="number" class="form-control" id="current-stock" v-model="currentStock" readonly>
					</div>
					<div class="form-group">
						<label for="new-stock">Update Stock Level</label>
						<input type="number" class="form-control" id="new-stock" v-model="newStock">
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-primary" @click="updateStockLevels">Add</button>
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>

	<!-- Update Shipping Days modal window -->
	<div class="modal fade" id="UpdateShippingModel" tabindex="-1" role="dialog" aria-labelledby="createDialogLabel"
		aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="createDialogLabel">
						Update Shipping Days
					</h5>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
				<div class="form-group shipping-from-group">
					<label for="warehouse-type">Warehouse Type<span class="required-field text-danger">*</span></label>
					<select v-model="selectedWarehouseType" id="warehouse-type">
						<option v-for="type in uniqueWarehouseTypes" :key="type">{{ type }}</option>
					</select>
				</div>
				<div class="form-group">
					<label for="shipping-days">Average no of shipping days from Central to National<span
						class="required-field text-danger">*</span></label>
					<input type="number" class="form-control" id="shipping-days" v-model="shippingDays"
					placeholder="Average no of shipping days from Central to National">
				</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="save-btn" @click="updateShippingDays">Save</button>
					<button type="button" class="cancle-btn" data-bs-dismiss="modal"> Cancel </button>
				</div>
			</div>
		</div>
	</div>
	
	<!-- Confirm Shipping Modal -->
	<div class="modal fade" id="confirmShippingModal" tabindex="-1" role="dialog" aria-labelledby="confirmShippingModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmShippingModalLabel">Confirm Shipping</h5>
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Would you like to add the incoming volume of <span class="highlight">{{ incomingVolume }}</span> items to the article stock?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">No</button>
            <button type="button" class="btn btn-primary" @click="confirmShippingAction">Yes</button>
          </div>
        </div>
      </div>
    </div>

</template>
<script>
import {
	CREATE_WAREHOUSE,
} from "@/Core/store/action-types";
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import { API_URL } from "@/Core/helpers/utils";

export default {
	data() {
		return {
			formData: {
				name: '',
				warehouse_type: '',
				supplier: ''
			},
			warehouse_type_initiate: ['central/production', 'national/distribution'],
			warehouse_data: [],
			selectedWarehouseId: null, // Initialize selectedWarehouseId
			articles: [], // Initialize articles
			selectedWarehouse: null, // For selected warehouse
			selectedArticle: null, // For selected article
			moveStock: 0,
			maxStock: 0,
			nationalWarehouses: [],
			selectedWarehouseName: '', // To hold the name of the selected warehouse
			currentStock: 0, // To hold the current stock level
			newStock: 0, // To hold the new stock level input
			incomingVolume: 0,
		}
	},
	mutations: {
		setErrorMessage(state, message) {
			state.errorMessage = message;
		}
	},
	mounted() {
		document.querySelectorAll('.arrow-container').forEach(el => {
			el.addEventListener('click', this.toggleAccordion);
		});

		// API call on page load
		this.fetchInitialData();
	},
	computed: {
		...mapState('user', ['userProfile']),

		uniqueWarehouseTypes() {
			return [...new Set(this.warehouse_data.map(warehouse => warehouse.type))];
		}
	},
	methods: {
		...mapActions('user', [CREATE_WAREHOUSE]),
		...mapActions('user', ['fetchInitialData']),

		showCreateDialog() {
			window.$(`#createWarehousModel`).modal("toggle");
		},

		showupdateshippingDialog() {
			// Updated implementation for showCreateDialog
			window.$(`#UpdateShippingModel`).modal("toggle");
		},

		showUpdateStockDialog(warehouseId) {
			// Find the warehouse by ID from the warehouse_data array
			const warehouse = this.warehouse_data.find(w => w.id === warehouseId);
			if (warehouse) {
				this.selectedWarehouseName = warehouse.name; // Set the warehouse name
				this.articles = warehouse.article_data; // Populate articles from the selected warehouse
				this.selectedArticle = null; // Reset selected article
				this.currentStock = 0; // Reset current stock
				this.newStock = 0; // Reset new stock

				window.$(`#updateStockModal`).modal("toggle"); // Show the modal
			}
		},

		updateCurrentStock() {
			// Find the selected article and update the current stock
			const selected = this.articles.find(article => article.id === this.selectedArticle);
			if (selected) {
				this.currentStock = selected.central_stock; // Set current stock to central_stock of selected article
			} else {
				this.currentStock = 0; // Reset if no article is selected
			}
		},
		
		async updateShippingDays() {
			const token = this.$store.getters['user/userProfile'].access; // Get the token from Vuex store
			const payload = {
				article_id: this.selectedArticle,
				average_shipping_days: this.shippingDays
			};

			try {
				const response = await axios.patch(`${API_URL}/api/v1/warehouse/update-shipping-days/`, payload, {
					headers: { Authorization: `Bearer ${token}` } // Include the token in the headers
				});
				console.log(`Shipping days updated successfully for article ID: ${this.selectedArticle}`, response.data);
				window.$(`#updateShippingModel`).modal("hide"); // Close the modal after updating
				this.fetchInitialData(); // Optionally refresh data to reflect changes
			} catch (error) {
				console.error('Error updating shipping days:', error);
				this.$alertify.notify("Failed to update shipping days.", "error", 3);
			}
		},

		// Method to update stock levels
		async updateStockLevels() {
			const token = this.$store.getters['user/userProfile'].access; // Get the token from Vuex store
			const payload = {
				article_id: this.selectedArticle,
				central_stock: this.newStock // This value will be added to the current central_stock
			};

			try {
				const response = await axios.patch(`${API_URL}/api/v1/articles/update-stock/`, payload, {
					headers: { Authorization: `Bearer ${token}` } // Include the token in the headers
				});
				console.log(`Stock updated successfully for article ID: ${this.selectedArticle}`, response.data);
				window.$(`#updateStockModal`).modal("hide"); // Close the modal after updating
				this.fetchInitialData(); // Optionally refresh data to reflect changes
			} catch (error) {
				console.error('Error updating stock:', error);
				this.$alertify.notify("Failed to update stock.", "error", 3);
			}
		},

		updateMaxStock() {
			const selected = this.articles.find(article => article.id === this.selectedArticle);
			this.maxStock = selected ? selected.stock_quantity : 0; // Set max stock based on selected article
		},

		showMoveStockDialog(warehouseId) {
			this.selectedWarehouseId = warehouseId; // Set the selected warehouse ID
			const warehouse = this.warehouse_data.find(w => w.id === warehouseId);

			// Check if warehouse exists and has articles
			if (warehouse && warehouse.article_data) {
				this.articles = warehouse.article_data; // Use existing article data
			} else {
				this.articles = []; // Fallback to an empty array if no articles
			}

			// Filter national/distribution warehouses
			this.nationalWarehouses = this.warehouse_data.filter(w => w.type === 'national/distribution');

			console.log("selectedWarehouseId", this.selectedWarehouseId);
			console.log("articles", this.articles);

			// Show the modal after setting articles
			window.$(`#moveStockModal`).modal("toggle");
		},

		saveMoveStock() {
			const token = this.$store.getters['user/userProfile'].access; // Get the token from Vuex store
			const payload = {
				from_warehouse_id: this.selectedWarehouseId, // ID of the selected warehouse
				transfers: [
					{
						article_id: this.selectedArticle, // ID of the selected article
						quantity: this.moveStock, // Quantity to transfer
						to_warehouse_id: this.selectedWarehouse // ID of the destination warehouse
					}
				]
			};

			axios.post(`${API_URL}/api/v1/warehouse/transfer/`, payload, {
				headers: { Authorization: `Bearer ${token}` } // Include the token in the headers
			})
				.then(response => {
					console.log('Stock moved successfully:', response.data);
					this.fetchInitialData(); // Refresh data to reflect changes
					window.$(`#moveStockModal`).modal("hide"); // Close the modal
				})
				.catch(error => {
					console.error('Error moving stock:', error);
					this.$alertify.notify("Failed to move stock.", "error", 3);
				});
		},

		confirmShipping(articleId, warehouseId, incomingVolume) {
			this.selectedArticleId = articleId;
			this.selectedWarehouseId = warehouseId;
			this.incomingVolume = incomingVolume;
			window.$('#confirmShippingModal').modal('show');
		},

		confirmShippingAction() {
			const token = this.$store.getters['user/userProfile'].access; // Get the token from Vuex store
			const payload = {
				from_warehouse_id: this.selectedWarehouseId,
				arrivals: [
					{
						article_id: this.selectedArticleId,
						source_warehouse_id: this.selectedWarehouseId,
						destination_warehouse_id: this.selectedWarehouseId,
					}
				]
			};

			axios.post(`${API_URL}/api/v1/warehouse/confirm-arrival/`, payload, {
				headers: { Authorization: `Bearer ${token}` } // Include the token in the headers
			})
			.then(response => {
				console.log('Stock arrival confirmed successfully:', response.data);
				this.fetchInitialData(); // Refresh data to reflect changes
				window.$('#confirmShippingModal').modal('hide');
			})
			.catch(error => {
				console.error('Error confirming stock arrival:', error);
				this.$alertify.notify("Failed to confirm stock arrival.", "error", 3);
			});
		},
		closeModal() {
			window.$('#confirmShippingModal').modal('hide');
		},

		toggleAccordion(event) {
			const acc = event.currentTarget.closest('.accordion');
			const content = acc.querySelector('.content');
			const isOpen = acc.classList.toggle('active');
			content.style.maxHeight = isOpen ? `${content.scrollHeight}px` : '0';
		},

		saveWarehouse() {
			console.log("values==========", this.formData)
			const postData = {
				name: this.formData.name,
				type: this.formData.warehouse_type,
				parent: this.formData.parent,
				supplier: this.formData.supplier
			};
			this[CREATE_WAREHOUSE]({ obj: postData })
				.then(() => {
					window.$(`#${this.modalId}`).modal("hide");
					this.$alertify.notify(
						"Warehouse successfully created.",
						"success",
						3
					);
					this.fetchInitialData();
				})
				.catch((e) => {
					console.log("error", e);
					this.$alertify.notify(
						"Warehouse with this name already exists.",
						"error",
						3
					);

					this.errMsg = e.message;
					this.$store.commit('detail', e.message || 'something went wrong');
				});
		},
		async fetchInitialData() {
			const token = this.$store.getters['user/userProfile'].access;
			try {
				const response = await axios.get(`${API_URL}/api/v1/warehouse/`, {
					headers: { Authorization: `Bearer ${token}` }
				});
				this.warehouse_data = response?.data?.results;

				// Filter national/distribution warehouses
				this.nationalWarehouses = this.warehouse_data.filter(w => w.type === 'national/distribution');
			} catch (error) {
				console.error('API call failed:', error);
			}
		},
	},
	beforeUnmount() {
		document.querySelectorAll('.arrow-container').forEach(el => {
			el.removeEventListener('click', this.toggleAccordion);
		});
	},
};
</script>
<style scoped>
/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.heading-content {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
}

.listWarehouse_title {
	margin: 0 0 15px 0 !important;
	padding: 0 !important;
}

.listWarehouse ul {
	border: 1px solid #e9f0f7;
}

.listWarehouse ul li {
	border: 1px solid #e9f0f7;
	padding: 15px;
}

.accordion {
	margin: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	overflow: hidden;
}

.handle {
	background-color: #fff;
	color: white;
	cursor: pointer;
	padding: 10px;
	margin: 0;
	font-size: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: background-color 0.3s;
}

.arrow {
	transition: transform 0.3s ease;
}

.content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease;
	padding: 0 10px;
}

.content h1 {
	margin-bottom: 10px !important;
	text-align: center !important;
}

.active .arrow {
	transform: rotate(0deg);
	transition: 0.5s;
}


.dashboard-top-section span {
	color: #3f3f40 !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 43px !important;
}

.handle:hover {
	background: transparent !important;
}

.handle {
	background-color: #fff !important;
}

.content p {
	margin: 0 !important;
	padding: 10px !important;
}

.accordion.active .arrow-container {
	transform: rotate(90deg);
	transition: 0.5s ease-in-out;
}

span.green {
	width: 20px;
	height: 20px;
	background: green;
	border-radius: 100%;
	margin-left: 10px;
}

span.yellow {
	width: 25px;
	height: 25px;
	background: yellow;
	border-radius: 100px;
	margin-left: 10px;
}

span.red {
	width: 25px;
	height: 25px;
	background: red;
	border-radius: 100px;
	margin-left: 10px;
}

#table {
	width: 100%;
}

#table tbody tr th {
	color: #aaabad !important;
	font-size: 11px !important;
	font-weight: 500 !important;
	font-style: normal !important;
	line-height: 14px !important;
	color: #aaabad !important;
	margin: 0 !important;
	padding: 8px 0 !important;
	border-bottom: 2px solid #eaf0f7 !important;
}

#table td {
	border-color: #eaf0f7;
	font-size: 16px;
}

#table th,
#table td {
	vertical-align: top;
	border-top: 1px solid #eaf0f7;
	padding: 0.25rem 0px 0.25rem 0;
	font-size: 16px;
	color: #3f3f40;
	font-weight: 400;
}


.inventory-status {
	position: relative;
}

/* Updated tooltip CSS for visibility on hover */
.form-group:hover .tooltip {
	display: block;
	opacity: 1;
}

.tooltip {
	display: none;
	position: absolute;
	background-color: #333;
	color: #fff;
	padding: 5px;
	border-radius: 5px;
	font-size: 12px;
	white-space: nowrap;
	z-index: 1000;
	bottom: -35px;
	/* Adjust as necessary */
	left: 0;
	transform: translateX(0);
	opacity: 0;
	transition: opacity 0.3s ease;
}

/* Show the tooltip on hover */
td:hover .tooltip {
	display: block;
	opacity: 1;
}

.dashboard-top-section h1 {
	margin: 0 !important;
	padding: 0 !important;
}

.arrow {
	display: inline-block;
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 12px solid #5168f5;
}

.button-group[data-v-19326c1e] {
	display: flex;
	gap: 10px;
	margin: 0px 0px 0px 880px;
}

.red {
	background-color: red;
}

.green {
	background-color: green;
}

.yellow {
	background-color: yellow;
}
</style>