<template>
  <div class="contant-box-main customer-list-box">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img src="@/../public/assets/images/star-icon.png" alt="" /> Lowest Inventory Turnover (National Warehouse)
        </h6>
        <div class="">
          <select class="for" id="adminfilter" v-model="time" @change="onTimeChange">
            <option v-for="option in timeOptions" :key="option">{{ option }}</option>
          </select>
        </div>
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow ? 'h-100' : ''">
          <div class="loader-main" v-if="loadindShow">
            <div class="spinner-border" role="status" style="width: 36px; height: 36px; color: #5e66fa">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Article ID</th>
                <th>Name</th>
                <th>Warehouse</th>
                <th>Quantity</th>
                <th>Turnover Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="article in articles" :key="article.article_id">
                <td>{{ article.number }}</td>
                <td>{{ article.name }}</td>
                <td>{{ article.warehouse }}</td>
                <td>{{ article.quantity }}</td>
                <td>{{ article.turnover }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="contant-footer d-flex justify-content-end">
      <a role="button" class="view-more-btn" @click="viewAllArticles">View All <img
          src="@/../public/assets/images/right-sky.png" alt="" /></a>
    </div>
    <div class="modal fade" id="viewAllModal" tabindex="-1" role="dialog" aria-labelledby="viewAllModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewAllModalLabel">All Articles Low Stock (National Warehouse)</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead>
                <tr>
                  <th>Article ID</th>
                  <th>Name</th>
                  <th>Warehouse</th>
                  <th>Quantity</th>
                  <th>Turnover Rate</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="article in allArticles" :key="article.article_id">
                  <td>{{ article.number }}</td>
                  <td>{{ article.name }}</td>
                  <td>{{ article.warehouse }}</td>
                  <td>{{ article.quantity }}</td>
                  <td>{{ article.turnover }}</td>
                  <td>{{ article.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from "@/Core/helpers/utils";

export default {
  name: "LowestInventoryTurnOverNationalWarehouse",
  data() {
    return {
      articles: [],
      allArticles: [],
      time: "month",
      timeOptions: ["week", "month", "quarter", "half a year", "year"],
      loadindShow: false,
    };
  },
  methods: {
    async fetchArticles() {
      const token = this.$store.getters['user/userProfile'].access;
      this.loadindShow = true; // Show loader
      try {
        const response = await axios.get(`${API_URL}/api/v1/warehouse/lowest-inventory-turnover/`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log("response", response);
        // Directly assign the data
        this.articles = response.data;
        console.log("articles", this.articles);
      } catch (error) {
        // Log the error response for more details
        console.error('Error fetching articles:', error.response ? error.response.data : error.message);
      } finally {
        this.loadindShow = false; // Hide loader
      }
    },
    onTimeChange() {
      this.fetchArticles();
    },
    viewAllArticles() {
      this.allArticles = [...this.articles]; // Ensure reactivity
      this.$nextTick(() => {
        window.$('#viewAllModal').modal('show'); // Show the modal
      });
    },
    closeModal() {
      window.$('#viewAllModal').modal('hide'); // Hide the modal
    },
  },
  mounted() {
    this.fetchArticles();
  },
};
</script>

<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px;
  border: 1px solid #cacbcd;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}

.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
}
</style>