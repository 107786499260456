export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';

export const LIST_USERS = 'LIST_USERS';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const FULL_DELETE_USER = 'FULL_DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_OWN_PASSWORD = 'CHANGE_OWN_PASSWORD';
export const CHANGE_PROFILE_IMAGE = 'CHANGE_PROFILE_IMAGE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_ORG_UPLOAD_DATA = 'UPDATE_ORG_UPLOAD_DATA'

export const LIST_STORES = 'LIST_STORES';
export const CREATE_STORE = 'CREATE_STORE';
export const UPDATE_STORE = 'UPDATE_STORE';
export const DELETE_STORE = 'DELETE_STORE';
export const LIST_ARTICLES = 'LIST_ARTICLES';
export const CREATE_ARTICLES = 'CREATE_ARTICLES';
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES';
export const TRENDING_ARTICLES = 'TRENDING_ARTICLES';
export const RECOMMENDED_ARTICLES = 'RECOMMENDED_ARTICLES';
export const CONNECTED_ARTICLES = 'CONNECTED_ARTICLES';
export const LIST_ARTICLES_ALL = 'LIST_ALL_ARTICLES';
export const BOUGHT_TOGATHER_ARTICLES = 'BOUGHT_TOGATHER_ARTICLES'
export const MEMBER_ARTICLES = 'MEMBER_ARTICLES'
export const MOST_LIKELY_TO_BUY_ARTICLES = 'MOST_LIKELY_TO_BUY_ARTICLES'
export const MOST_LIKELY_TO_BUY_PRODUCT = 'MOST_LIKELY_TO_BUY_PRODUCT'
export const ORG_MOST_LIKELY_TO_BUY_ARTICLES = 'ORG_MOST_LIKELY_TO_BUY_ARTICLES'
export const SIMILAR_ARTICLES = 'SIMILAR_ARTICLES'
export const BUT_AGAIN_ARTICLES = 'BUT_AGAIN_ARTICLES'
export const MOST_SOLD_ARTICLES = 'MOST_SOLD_ARTICLES'
export const TOP_SELLING_ARTICLES = 'TOP_SELLING_ARTICLES'
export const GET_RECEIPTS_STATS='GET_RECEIPTS_STATS'

export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const LIST_PRODUCT_CATEGORIES = 'LIST_PRODUCT_CATEGORIES';
export const LIST_PRODUCTS_ALL = 'LIST_PRODUCTS_ALL';
export const CONNECTED_PRODUCTS = 'CONNECTED_PRODUCTS';
export const TRENDING_PRODUCTS = 'TRENDING_PRODUCTS';
export const RECOMMENDED_PRODUCTS = 'RECOMMENDED_PRODUCTS';
export const CATEGORIESVISECOUNT = 'CATEGORIESVISECOUNT';
export const ADD_SELECTED_PRODUCTS='ADD_SELECTED_PRODUCTS'
export const DELETE_SELECTED_PRODUCT = 'REMOVE_SELECTED_PRODUCT'
export const TOP_SELLING_PRODUCTS = 'TOP_SELLING_PRODUCTS'

export const GET_METRICS = 'GET_METRICS';

export const GET_FACEBOOK_ADACCOUNTS_LIST='GET_FACEBOOK_ADACCOUNTS_LIST'

export const LIST_ORGS  = 'LIST_ORGS';
export const GET_ORG ='GET_ORG';
export const DELETE_ORG = 'DELETE_ORG';
export const UPDATE_ORG = 'UPDATE_ORG';
export const CREATE_ORG = 'CREATE_ORG';
export const CHANGE_ORG_CURRENCY='CHANGE_ORG_CURRENCY'
export const CHANGE_ORG_CLV_YEAR = 'CHANGE_ORG_CLV_YEAR'
export const UPDATE_INITIAL_CURRENCY_CLV="UPDATE_INITIAL_CURRENCY_CLV"
export const GET_SHIPPING_COGS= 'GET_SHIPPING_COGS';
export const UPDATE_SHIPPING_COGS= 'UPDATE_SHIPPING_COGS'
export const FACEBOOK_CONNECTION= 'FACEBOOK_CONNECTION';
export const FACEBOOK_DISCONNECT= 'FACEBOOK_DISCONNECT'
export const WOOCOMMERCE_CONNECTION= 'WOOCOMMERCE_CONNECTION';
export const WOOCOMMERCE_DISCONNECT= 'WOOCOMMERCE_DISCONNECT'
export const SHOPIFY_CONNECTION= 'SHOPIFY_CONNECTION';
export const SENDGRID_CONNECTION= 'SENDGRID_CONNECTION';
export const SENDGRID_DISCONNECT= 'SENDGRID_DISCONNECT';
export const SHOPIFY_DISCONNECT= 'SHOPIFY_DISCONNECT'
export const GOOGLE_DISCONNECT='GOOGLE_DISCONNECT'
export const GOOGLE_ANALYTICS_DISCONNECT='GOOGLE_ANALYTICS_DISCONNECT'
export const GET_GOOGLE_ANALYTICS_ACCOUNT='GET_GOOGLE_ANALYTICS_ACCOUNT'
export const SET_GOOGLE_ANALYTICS_ACCOUNT='SET_GOOGLE_ANALYTICS_ACCOUNT'
export const GET_SENDGRID_TEMPLATES = 'GET_SENDGRID_TEMPLATES'
export const GET_SENDGRID_CONTACT_LIST = 'GET_SENDGRID_CONTACT_LIST'
export const GET_SENDGRID_TEMPLATE_VERSION = 'GET_SENDGRID_TEMPLATE_VERSION'
export const GET_ORG_STATS = 'GET_ORG_STATS'

export const LIST_AUDIENCE = 'LIST_AUDIENCE';
export const DELETE_AUDIENCE = 'DELETE_AUDIENCE';
export const UPDATE_AUDIENCE = 'UPDATE_AUDIENCE';
export const CREATE_AUDIENCE = 'CREATE_AUDIENCE';
export const AUDIENCE_DETAIL = 'AUDIENCE_DETAIL';
export const AUDIENCE_CUSTOMER_DETAIL = 'AUDIENCE_CUSTOMER_DETAIL'
export const GET_TOP_PERFORMING_AUDIENCES='GET_TOP_PERFORMING_AUDIENCES'
export const CREATE_FACEBOOK_CUSTOM_AUDIENCE = 'CREATE_FACEBOOK_CUSTOM_AUDIENCE'
export const UPDATE_FACEBOOK_CUSTOM_AUDIENCE = 'UPDATE_FACEBOOK_CUSTOM_AUDIENCE'
export const DELETE_FACEBOOK_CUSTOM_AUDIENCE = 'DELETE_FACEBOOK_CUSTOM_AUDIENCE'

export const GET_GDPR_DATA = 'GET_GDPR_DATA';
export const LIST_CUSTOMERS = 'LIST_CUSTOMERS';
export const LIST_CUSTOMER_RECEIPTS = 'LIST_CUSTOMER_RECEIPTS';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const LIST_RECENT_CUSTOMERS = 'LIST_RECENT_CUSTOMERS';
export const CUSTOMER_DETAILS = 'CUSTOMER_DETAILS';
export const LIST_CUSTOMER_PURCHASES=' LIST_CUSTOMER_PURCHASES'
export const GET_MOST_VALUABLE_CUSTOMERS = 'GET_MOST_VALUABLE_CUSTOMERS'
export const GET_REOCCURING_CUSTOMERS= 'GET_REOCCURING_CUSTOMERS'

export const LIST_RECEIPT_ORDERS = 'LIST_RECEIPT_ORDERS';
export const LIST_ORDERS = 'LIST_ORDERS';
export const GET_AVG_ORDER_VALUE='GET_AVG_ORDER_VALUE'
export const LIST_RECEIPTS='LIST_RECEIPTS'
export const ARTICLE_FILE_UPLOAD ='ARTICLE_FILE_UPLOAD';
export const INVENTORY_FILE_UPLOAD ='INVENTORY_FILE_UPLOAD';
export const RECEIPT_FILE_UPLOAD ='RECEIPT_FILE_UPLOAD'
export const SHOPIFY_RECEIPT_FILE_UPLOAD ='SHOPIFY_RECEIPT_FILE_UPLOAD'
export const MULTIPLE_RECEIPT_FILE_UPLOAD ='MULTIPLE_RECEIPT_FILE_UPLOAD'
export const CUSTOMER_FILE_UPLOAD='CUSTOMER_FILE_UPLOAD'
export const SALES_CHANNEL_FILE_UPLOAD='SALES_CHANNEL_FILE_UPLOAD'

export const UPDATE_CLV_YEAR="UPDATE_CLV_YEAR"
export const UPDATE_CURRENCY="UPDATE_CURRENCY"

export const GENERATE_RECEIPT_API_TOKEN = 'GENERATE_RECEIPT_API_TOKEN'
export const GENERATE_RECOMMENDATIONS_API_TOKEN = 'GENERATE_RECOMMENDATIONS_API_TOKEN'
export const GET_STORE_BY_MERCHANT_ID = 'GET_STORE_BY_MERCHANT'
export const UPDATE_ORG_EXTERNAL_API= 'UPDATE_ORG_EXTERNAL_API'
export const GET_EXTERNAL_API = 'GET_EXTERNAL_API'


export const LIST_SHIPPING_CHARGES = 'LIST_SHIPPING_CHARGES'
export const GET_SHIPPING_CHARGE_DETAILS = 'GET_SHIPPING_CHARGE_DETAILS'
export const CREATE_SHIPPING_CHARGE= 'CREATE_SHIPPING_CHARGE'
export const UPDATE_SHIPPING_CHARGE = 'UPDATE_SHIPPING_CHARGE'
export const DELETE_SHIPPING_CHARGE = 'DELETE_SHIPPING_CHARGE'
export const SHIPPING_CHARGES_FILE_UPLOAD = 'SHIPPING_CHARGES_FILE_UPLOAD'

export const SAVE_CONTACT_LIST = 'SAVE_CONTACT_LIST'
export const GET_UNLAYER_TEMPLATES = 'GET_UNLAYER_TEMPLATES'
export const GET_SINGLE_SENDS = 'GET_SINGLE_SENDS'
export const SEND_EMAIL_TO_CUSTOMER = 'SEND_EMAIL_TO_CUSTOMER'
export const SET_SUBSCRIPTION_STATUS = 'SET_SUBSCRIPTION_STATUS'
export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE'
export const LIST_WAREHOUSE = 'LIST_WAREHOUSE'