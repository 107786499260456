import {
  SET_USER,
  SET_USERS_LIST,
  REMOVE_USER_FROM_LIST,
  ADD_NEW_USER,
  EDIT_USER_FROM_LIST,
  EDIT_PROFILE_IMAGE,
  SET_USER_DETAILS,
  SET_ORG_UPLOAD_DATA,
  SET_CURRENCY,
  SET_CLV_YEAR
} from "@/Core/store/mutation-types";

import {
  REGISTER,
  LOGOUT,
  LOGIN,
  LIST_USERS,
  DEACTIVATE_USER,
  UPDATE_USER,
  FULL_DELETE_USER,
  CHANGE_OWN_PASSWORD,
  CHANGE_PROFILE_IMAGE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_USER_DETAILS,
  UPDATE_ORG_UPLOAD_DATA,
  UPDATE_CURRENCY,
  UPDATE_CLV_YEAR,
  CREATE_WAREHOUSE
} from "@/Core/store/action-types";

import ServerErrorHandler from "@/Core/helpers/ServerErrorHandler";
import { authHeader, API_URL } from "@/Core/helpers/utils";
import { defineRulesFor } from "@/Core/helpers/ability";
import axios from "axios";

function saveUserProfile(userProfile) {
  localStorage.setItem("userProfile", JSON.stringify(userProfile));
}

function loadUserProfile() {
  try {
    return JSON.parse(localStorage.getItem("userProfile") || "{}");
  } catch {
    return {};
  }
}
// const dataURLtoFile = (dataurl, filename) => {
//   const arr = dataurl.split(',')
//   const mime = arr[0].match(/:(.*?);/)[1]
//   const bstr = atob(arr[1])
//   let n = bstr.length
//   const u8arr = new Uint8Array(n)
//   while (n) {
//     u8arr[n - 1] = bstr.charCodeAt(n - 1)
//     n -= 1 // to make eslint happy
//   }
//   return new File([u8arr], filename, { type: mime })
// }

const initialState = {
  // my user profile
  userProfile: loadUserProfile(),
  // list of external users available to manage
  usersList: [],
};

const state = { ...initialState };

const actions = {
  [CREATE_WAREHOUSE]({ getters }, { obj }) {
    return axios
      .post(`${API_URL}/api/v1/warehouse/create/`, obj, {
        headers: authHeader(getters.userProfile.access),
      })
  },
  [REGISTER]({ getters, commit }, { userToCreate, actions }) {
    let url = `${API_URL}/api/v1/account/`;
    return axios
      .post(url, userToCreate, {
        headers: authHeader(getters.userProfile.access),
      })
      .then(function (response) {
        commit(ADD_NEW_USER, response.data);
        return response.data;
      })
      .catch(function (error) {
        ServerErrorHandler(actions, error.response);
        throw new Error("");
      });
  },

  [LOGOUT]({ commit }) {
    commit(SET_USER, {});
  },

  [GET_USER_DETAILS]({ getters, commit }, userId) {
    let url = `${API_URL}/api/v1/account/${userId}/`;

    return axios
      .get(url, {
        headers: authHeader(getters.userProfile.access),
      })
      .then(function (response) {
        commit(SET_USER_DETAILS, response.data);
        return response.data;
      })
      .catch(function () {
        throw new Error("");
      });
  },
  [UPDATE_USER]({ getters, commit }, { userToUpdate, actions }) {
    let url = `${API_URL}/api/v1/account/${userToUpdate.id}/`;

    return axios
      .patch(url, userToUpdate, {
        headers: authHeader(getters.userProfile.access),
      })
      .then(function (response) {
        commit(EDIT_USER_FROM_LIST, response.data);
        return response.data;
      })
      .catch(function (error) {
        actions ? ServerErrorHandler(actions, error.response) : null;
        throw new Error("");
      });
  },

  [LOGIN]({ commit }, { values, actions, ability }) {
    let getTokenURL = `${API_URL}/api/v1/token/`;
    var userData = {};

    return axios
      .post(getTokenURL, values)
      .then(function (response) {
        userData = response.data;
        let getUserUrl = `${API_URL}/api/v1/account/${userData.id}/`;
        return axios.get(getUserUrl, {
          headers: authHeader(userData.access),
        });
      })
      .then(function (response) {
        userData = Object.assign(userData, response.data);
        commit(SET_USER, userData);
        ability.update(defineRulesFor(userData));
      })
      .catch(function (error) {
        commit(SET_USER, {});
        ServerErrorHandler(actions, error.response);
        ability.update([]);
        throw new Error("");
      });
  },

  [LIST_USERS]({ commit, getters }, params) {
    let url = `${API_URL}/api/v1/account/`;

    return axios
      .get(url, {
        params,
        headers: authHeader(getters.userProfile.access),
      })
      .then(function (response) {
        if (params.persist) commit(SET_USERS_LIST, response.data);
        return response.data;
      });
  },

  [DEACTIVATE_USER]({ commit, getters }, userId) {
    let url = `${API_URL}/api/v1/account/${userId}/deactivate/`;
    return axios
      .delete(url, {
        headers: authHeader(getters.userProfile.access),
      })
      .then(function (response) {
        commit(EDIT_USER_FROM_LIST, response.data);
        return response.data;
      });
  },

  [FULL_DELETE_USER]({ getters, commit }, userId) {
    let url = `${API_URL}/api/v1/account/${userId}/`;

    return axios
      .delete(url, {
        headers: authHeader(getters.userProfile.access),
      })
      .then(function () {
        commit(REMOVE_USER_FROM_LIST, userId);
      })
      .catch(function () {
        throw new Error("");
      });
  },

  [CHANGE_OWN_PASSWORD]({ getters }, { values, actions, userId }) {
    let url = `${API_URL}/api/v1/account/${userId}/password/`;

    return axios
      .put(url, values, {
        headers: authHeader(getters.userProfile.access),
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        ServerErrorHandler(actions, error.response);
        throw new Error("");
      });
  },
  [CHANGE_PROFILE_IMAGE]({ getters, commit }, image) {
    let url = `${API_URL}/api/v1/image_update`;
    // const file = dataURLtoFile(image,`${state.userProfile.email}.png`)
    const data = new FormData();
    data.append("image_url", image);
    return axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${getters.userProfile.access}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        let getUserUrl = `${API_URL}/api/v1/account/${getters.userProfile.id}/`;
        axios
          .get(getUserUrl, {
            headers: authHeader(getters.userProfile.access),
          })
          .then(function (response) {
            commit(EDIT_PROFILE_IMAGE, response.data.profile_image_url);
          });
        return response.data;
      })
      .catch(function () {
        throw new Error("");
      });
  },
  [UPDATE_ORG_UPLOAD_DATA]({ getters, commit }){
    let getUserUrl = `${API_URL}/api/v1/account/${getters.userProfile.id}/`;
    return axios
      .get(getUserUrl, {
        headers: authHeader(getters.userProfile.access),
      })
      .then(function (response) {
        commit(SET_ORG_UPLOAD_DATA, response.data.organization);
        return response.data;
      })
      .catch(function () {
        throw new Error("");
      });
  }
  ,
  [FORGOT_PASSWORD]({ getters }, { values, actions }) {
    let url = `${API_URL}/api/v1/forgot_password/`;
    console.log(values, "fef3ef", getters);
    return axios
      .post(url, values)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        ServerErrorHandler(actions, error.response);
        throw new Error("");
      });
  },
  [RESET_PASSWORD]({ getters }, { values, actions, token, id }) {
    let url = `${API_URL}/api/v1/change_password/${id}/${token}/`;
    console.log(values, "fef3ef", getters);
    return axios
      .post(url, values)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        ServerErrorHandler(actions, error.response);
        throw new Error("");
      });
  },
  [UPDATE_CURRENCY]({commit},params){
    commit(SET_CURRENCY,params.currency)
  },
  [UPDATE_CLV_YEAR]({commit},params){
    commit(SET_CLV_YEAR,params.clv_year)
  }
};

const mutations = {
  [SET_USER](state, user) {
    state.userProfile = user;
    saveUserProfile(state.userProfile);
    //  router.push('/')
  },
  [EDIT_PROFILE_IMAGE](state, data) {
    console.log(data);
    state.userProfile.profile_image_url = data;
    saveUserProfile(state.userProfile);
    //  router.push('/')
  },
  [SET_CURRENCY](state,data){
    state.userProfile.organization.currency=data
    saveUserProfile(state.userProfile);
  },
  [SET_CLV_YEAR](state,data){
    state.userProfile.organization.clv_year=data
    saveUserProfile(state.userProfile);
  },
  [SET_ORG_UPLOAD_DATA](state, data) {
    state.userProfile.organization.last_uploaded_article_count = data.last_uploaded_article_count;
    state.userProfile.organization.last_uploaded_article_timestamp = data.last_uploaded_article_timestamp;
    state.userProfile.organization.last_uploaded_customer_count = data.last_uploaded_customer_count;
    state.userProfile.organization.last_uploaded_customer_timestamp = data.last_uploaded_customer_timestamp;
    state.userProfile.organization.last_uploaded_sales_channel_count = data.last_uploaded_sales_channel_count;
    state.userProfile.organization.last_uploaded_sales_channel_timestamp = data.last_uploaded_sales_channel_timestamp;
    saveUserProfile(state.userProfile);
  },
  [SET_USERS_LIST](state, usersList) {
    state.usersList = usersList;
  },
  [SET_USER_DETAILS](state, userData) {
    state.userDetails = userData;
  },
  [ADD_NEW_USER](state, user) {
    state.usersList ? state.usersList.push(user) : "";
  },
  [EDIT_USER_FROM_LIST](state, editedUser) {
    if (state.usersList) {
      let idx = state.usersList.findIndex((u) => u.id == editedUser.id);

      if (idx > -1) {
        state.usersList[idx] = editedUser;
      }
    }
  },
  [REMOVE_USER_FROM_LIST](state, userId) {
    if (state.usersList) {
      let idx = state.usersList.findIndex((u) => u.id == userId);

      if (idx > -1) {
        state.usersList.splice(idx, 1);
      }
    }
  },
};

const getters = {
  userProfile(state) {
    return state.userProfile;
  },
  userDetails(state) {
    return state.userDetails;
  },
  usersList(state) {
    return state.usersList;
  },
  default_clv_year(state) {
    return state.default_clv_year;
  },
  default_currency(state) {
    return state.default_currency;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};