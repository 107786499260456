<template>
  <div class="contant-box-main">
    <div class="contant-header">
      <h6>
        <img src="./../../../public/assets/images/star-icon.png" alt="" />
       {{article?`${customerDetails?.organization_data?.name?.length>14?customerDetails?.organization_data?.name.slice(0,14)+'...':customerDetails?.company_name } is likely to purchase again: `:'Cart composition '}} 
      </h6>
      <ul class="nav nav-tabs align-items-center" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            :class="article ? 'nav-link active' : 'nav-link'"
            id="articles-tab"
            data-bs-toggle="tab"
            data-bs-target="#articles"
            type="button"
            role="tab"
            aria-controls="articles"
            aria-selected="false"
            @click="article = true"
          >
            Articles
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            :class="!article ? 'nav-link active' : 'nav-link'"
            id="cart-tab"
            data-bs-toggle="tab"
            data-bs-target="#cart"
            type="button"
            role="tab"
            aria-controls="cart"
            aria-selected="true"
            @click="article = false"
          >
            Cart composition
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content h-100 position-relative" id="myTabContent">
      <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
      <div
        :class="article ? 'tab-pane fade show active' : 'tab-pane fade'"
        id="articles"
        role="tabpanel"
        aria-labelledby="articles-tab"
      >
        <div class="contant-details">
           
          <div class="overflow-auto no-pagination">
            <table class="table table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in fields" v-bind:key="f.id">
                    {{ f.split("_").join(" ") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, counter) in items" v-bind:key="item.id">
                  <td class="font-light-text">{{ counter + 1 }}</td>
                  <td>{{ item.article_name }}</td>
                  <td class="font-light-text">
                    {{ item.next_purchase_date ? item.next_purchase_date : "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        :class="!article ? 'tab-pane fade show active' : 'tab-pane fade'"
        id="cart"
        role="tabpanel"
        aria-labelledby="cart-tab"
      >
      <!-- category wise count chart start -->
        <div class="pie-table">
          <DoughnutChart
            :chartData="testData"
            :options="options"
            class="canvas-outer"
          />
          <div>
            <ul class="ul-table">
              <li
                v-for="(data, index) in testData.datasets[0]['data']"
                v-bind:key="data"
              >
                <div class="detail-title">
                  <span
                    :style="{
                      'background-color':
                        testData.datasets[0]['backgroundColor'][index],
                    }"
                  ></span>
                  <p>{{ testData.labels[index] }}</p>
                </div>
                <span class="percentage-data"
                  >{{ Math.round((data * 100) / totalCount) }} %</span
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- category wise count chart end -->
        <!-- articles table start -->
        <table class="table table-sm likey_buy_table">
          <thead>
            <tr>
              <th></th>
              <th class="id-category" scope="col">Category</th>
              <th scope="col">Total Products</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in testData.datasets[0]['data']"
              v-bind:key="data"
            >
              <td class="category_number">{{ testData.numbers[index] }}</td>
              <td class="category_label">{{ testData.labels[index] }}</td>
              <td class="category_data font-light-text">{{ data }}</td>
            </tr>
          </tbody>
        </table>
        <!-- articles table end -->
      </div>
    </div>
  </div>
</template>
<script>
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { mapActions,mapGetters } from "vuex";
 // MEMBER_ARTICLES,
import {
  MOST_LIKELY_TO_BUY_PRODUCT,
  CATEGORIESVISECOUNT,
} from "@/Core/store/action-types";
Chart.register(...registerables);

export default {
  name: "MostLikelyToBuy",
  components: { DoughnutChart },
  computed:{
    ...mapGetters("customer", ["customerDetails"]),
  },
  data() {
    return {
      article: true,
      params: {
        persist: true,
      },
      fields: ["No.", "Product Name", "	Likely purchase period"],
      chartData: [],
      totalCount: 0,
      items: [],
      loadindShow:false,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      testData: {
        numbers: [],
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#C2CAFF",
              "#9AA2FF",
              "#868EFF",
              "#727AFF",
              "#5E66FA",
            ],
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("article", [MOST_LIKELY_TO_BUY_PRODUCT]),
    ...mapActions("product", [CATEGORIESVISECOUNT]),
    //get member products data
    InItProductDetails() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
        const customerId = this.$route.params.customerId;
         this.loadindShow = true
        this[MOST_LIKELY_TO_BUY_PRODUCT](customerId)
          .then((resp) => {
            this.items = resp;
            console.log("this.items product", this.items)
          })
          .catch((err) => {
            console.log("error=>", err);
          }).finally(()=>this.loadindShow=false)
      }
    },
    //get category wise count data
    InItCategoriesViseCount() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
        const customerId = this.$route.params.customerId;
        this.loadindShow = true
        this[CATEGORIESVISECOUNT]({ id: customerId })
          .then((res) => {
            1;
            this.testData.numbers = Array(Math.ceil(res.length))
              .fill(0)
              .map((e, i) => i + 1);
            res.map((item) => {
              this.testData.labels.push(item.category__name);
              this.chartData.push(item.total);
              this.totalCount += item.total;
            });
            this.testData.datasets[0].data = this.chartData;
          })
          .catch((err) => {
            console.error(err);
          }).finally(()=>this.loadindShow=false)
      }
    },
  },

  mounted() {
    this.InItCategoriesViseCount();
    this.InItProductDetails();
  },
};
</script>
<style>
</style>