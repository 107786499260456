<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="42px"
    height="42px"
    viewBox="0 0 42 42"
    version="1.1"
  >
    <g id="surface1">
      <!-- Document Icon -->
      <path
        style="stroke: none; fill-rule: nonzero; fill: rgb(36.862745%, 40%, 98.039216%); fill-opacity: 1;"
        d="M 10 0 L 32 0 C 33.1045695 0 34 0.8954305 34 2 L 34 40 C 34 41.1045695 33.1045695 42 32 42 L 10 42 C 8.8954305 42 8 41.1045695 8 40 L 8 2 C 8 0.8954305 8.8954305 0 10 0 Z"
      />
      <!-- Text Lines -->
      <rect
        x="12"
        y="8"
        width="18"
        height="4"
        style="stroke: none; fill-rule: nonzero; fill: rgb(95.294118%, 90.588235%, 91.764706%); fill-opacity: 1;"
      />
      <rect
        x="12"
        y="16"
        width="14"
        height="3"
        style="stroke: none; fill-rule: nonzero; fill: rgb(95.294118%, 90.588235%, 91.764706%); fill-opacity: 1;"
      />
      <rect
        x="12"
        y="22"
        width="10"
        height="3"
        style="stroke: none; fill-rule: nonzero; fill: rgb(95.294118%, 90.588235%, 91.764706%); fill-opacity: 1;"
      />
      <rect
        x="12"
        y="28"
        width="8"
        height="3"
        style="stroke: none; fill-rule: nonzero; fill: rgb(95.294118%, 90.588235%, 91.764706%); fill-opacity: 1;"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ArticleUploadIcon",
};
</script>

<style scoped></style>