// import inventoryApi from "./inventory.api";

const initialState = {};
const state = { ...initialState };

const actions = {
    // action methods
};

const mutations = {
    // mutation methods
};

const getters = {
    // getter methods
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};