<template>
  <!-- Log In page -->
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 align-self-center">
        <div class="">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-5 mx-auto">
                <div class="media mb-5 justify-content-center">
                  <img
                    src="../../public/assets/images/login_logo.png"
                    height="55"
                    alt="logo"
                    class="auth-logo"
                  />
                </div>
                <div class="card">
                  <div class="card-body">
                    <h2 class="form-title">Sign in</h2>
                    <Form
                      @submit="onSubmit"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                      class="form-horizontal auth-form my-4"
                      action="index.html"
                    >
                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.email }"
                      >
                        <label for="email">Email</label>
                        <Field
                          as="input"
                          type="email"
                          class="form-control form-control-danger"
                          name="email"
                          placeholder="Enter Email"
                        />
                        <div class="form-control-feedback">
                          {{ errors.email }}
                        </div>
                      </div>
                      <!--end form-group-->

                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.password }"
                      >
                        <label for="password">Password</label>
                        <Field
                          as="input"
                          :type="[
                            showPassword.indexOf(1) > 0 ? 'text' : 'password',
                          ]"
                          class="form-control"
                          name="password"
                          placeholder="Enter password"
                        />
                        <div class="control">
                          <button
                            type="button"
                            class="button"
                            @click="toggleShow(1)"
                          >
                            <span
                              class="eye-iocon"
                              :class="{
                                'eye-off': showPassword.indexOf(1) > 0,
                                'eye-on': showPassword.indexOf(1) < 0,
                              }"
                            >
                              <img
                                src="@/../public/assets/images/eyeicon.png"
                                alt=""
                              />
                            </span>
                          </button>
                        </div>
                        <div class="form-control-feedback">
                          {{ errors.password }}
                        </div>
                      </div>
                      <!--end form-group-->

                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.detail }"
                      >
                        <Field
                          as="input"
                          type="hidden"
                          class="form-control"
                          name="detail"
                        />
                        <div class="form-control-feedback">
                          {{ errors.detail }}
                        </div>
                      </div>
                      <!--end form-group-->

                      <div class="form-group mb-0 row">
                        <div
                          class="
                            col-12
                            mt-2
                            d-flex
                            align-items-center
                            justify-content-between
                          "
                        >
                          <router-link
                            to="/forgotpassword"
                            class="forgot-password"
                          >
                            forgot Password?</router-link
                          >
                          <button
                            class="
                              btn btn-block
                              waves-effect waves-light
                              login-button
                            "
                            type="submit"
                          >  
                           <div class="spinner-border" role="status" style="width: 20px; height: 20px;" v-if="isLoading"> 
                            <span class="visually-hidden">Loading...</span>
                          </div>
                          <span  v-else>Log In <i class="fas fa-sign-in-alt ml-1"></i></span>
                          </button>
                        </div>
                        <!--end col-->
                      </div>
                      <!--end form-group--> </Form
                    ><!--end form-->
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>

            <!--end row-->
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Log In page -->
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

import { LOGIN,UPDATE_INITIAL_CURRENCY_CLV } from "@/Core/store/action-types";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";

export default {
  name: "Login",
  components: {
    Form,
    Field,
  },
  mixins: [UserRoleMixin],
  methods: {
    ...mapActions("user", [LOGIN]),
    ...mapActions("org",[UPDATE_INITIAL_CURRENCY_CLV]),
    onSubmit(values, actions) {
      this.isLoading=true
      this[LOGIN]({ values, actions, ability: this.$ability })
        .then(() => {
          this[UPDATE_INITIAL_CURRENCY_CLV]()
          this.$router.push("/dashboard")

          // got to the homepage 
        })
        .catch(() => {
          console.log("FEwwefew")
        }) //stay on a page
        .finally(()=>{
          this.isLoading=false
         })
    },
    toggleShow(id) {
      let i = this.showPassword.indexOf(id);
      if (i > 0) {
        this.showPassword.splice(i, 1);
      } else {
        this.showPassword.push(id);
      }
    },
  },
  data() {
    const schema = yup.object().shape({
      email: this.emailValidator(),
      password: yup
        .string()
        .min(3, "Password must be at least 6 characters")
        .required("Password is required"),
      detail: yup
        .string() // use it for backend errors
        .nullable()
        .notRequired(),
    });

    return {
      isLoading:false,
      schema,
      showPassword: [0],
    };
  },
};
</script>
<style scoped>
.login-button {
  background-color: #5e66fa;
  color: #ffffff;
  width:105px
}
.login-button:hover {
  background-color: #727aff;
  color: #fff;
}
.forgot-password {
  color: #5e66fa;
}
.form-title {
  text-align: center;
  margin: 15px 0 30px 0;
  color: #3f3f40;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
}
.control {
  position: absolute;
  /* top: 50%; */
  transform: translateY(-225%);
  right: 17px;
}
.control .button {
  border: none;
  background: transparent;
  padding: 0;
  margin-right: 8px !important;
}
.control .button .eye-iocon.eye-off {
  position: relative;
}
.control .button .eye-iocon.eye-off:before {
  content: "";
  position: absolute;
  width: 21px;
  height: 1px;
  background: black;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate3d(1, 1, 1, -45deg);
  top: 50%;
}
</style>


